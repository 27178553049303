import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const CiderQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProduct(
        filter: { categories: { elemMatch: { title: { in: ["Cider"] } } } }
      ) {
        edges {
          node {
            id
            name
            description
            slug {
              current
            }
          }
        }
      }
    }
  `)
  return (
    <ul>
      {data.allSanityProduct.edges.map(product => (
        <li key={product.node.id}>
          <Link to={`/products/${product.node.slug.current}`}>
            {product.node.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default CiderQuery
