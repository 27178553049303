import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const WineQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProduct(
        filter: {
          categories: {
            elemMatch: { title: { in: ["Red", "White", "Rosé", "Wine"] } }
          }
        }
      ) {
        edges {
          node {
            id
            name
            description
            slug {
              current
            }
          }
        }
      }
    }
  `)
  return (
    <ul>
      {data.allSanityProduct.edges.map(product => (
        <li key={product.node.id}>
          <Link to={`/products/${product.node.slug.current}`}>
            {product.node.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default WineQuery
