// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-sanity-article-slug-current-js": () => import("./../../../src/pages/articles/{SanityArticle.slug__current}.js" /* webpackChunkName: "component---src-pages-articles-sanity-article-slug-current-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-sanity-blog-slug-current-js": () => import("./../../../src/pages/blog/{SanityBlog.slug__current}.js" /* webpackChunkName: "component---src-pages-blog-sanity-blog-slug-current-js" */),
  "component---src-pages-carte-mdx": () => import("./../../../src/pages/carte.mdx" /* webpackChunkName: "component---src-pages-carte-mdx" */),
  "component---src-pages-categories-sanity-category-slug-current-js": () => import("./../../../src/pages/categories/{SanityCategory.slug__current}.js" /* webpackChunkName: "component---src-pages-categories-sanity-category-slug-current-js" */),
  "component---src-pages-cider-index-mdx": () => import("./../../../src/pages/cider/index.mdx" /* webpackChunkName: "component---src-pages-cider-index-mdx" */),
  "component---src-pages-cider-leavenworth-cider-tasting-mdx": () => import("./../../../src/pages/cider/leavenworth-cider-tasting.mdx" /* webpackChunkName: "component---src-pages-cider-leavenworth-cider-tasting-mdx" */),
  "component---src-pages-club-index-mdx": () => import("./../../../src/pages/club/index.mdx" /* webpackChunkName: "component---src-pages-club-index-mdx" */),
  "component---src-pages-club-pricing-mdx": () => import("./../../../src/pages/club/pricing.mdx" /* webpackChunkName: "component---src-pages-club-pricing-mdx" */),
  "component---src-pages-contact-index-mdx": () => import("./../../../src/pages/contact/index.mdx" /* webpackChunkName: "component---src-pages-contact-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-mdx": () => import("./../../../src/pages/jobs.mdx" /* webpackChunkName: "component---src-pages-jobs-mdx" */),
  "component---src-pages-leavenworth-mdx": () => import("./../../../src/pages/leavenworth.mdx" /* webpackChunkName: "component---src-pages-leavenworth-mdx" */),
  "component---src-pages-membership-faq-mdx": () => import("./../../../src/pages/membership/faq.mdx" /* webpackChunkName: "component---src-pages-membership-faq-mdx" */),
  "component---src-pages-membership-index-mdx": () => import("./../../../src/pages/membership/index.mdx" /* webpackChunkName: "component---src-pages-membership-index-mdx" */),
  "component---src-pages-membership-manage-mdx": () => import("./../../../src/pages/membership/manage.mdx" /* webpackChunkName: "component---src-pages-membership-manage-mdx" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-sanity-product-slug-current-js": () => import("./../../../src/pages/products/{SanityProduct.slug__current}.js" /* webpackChunkName: "component---src-pages-products-sanity-product-slug-current-js" */),
  "component---src-pages-stockists-mdx": () => import("./../../../src/pages/stockists.mdx" /* webpackChunkName: "component---src-pages-stockists-mdx" */),
  "component---src-pages-taste-js": () => import("./../../../src/pages/taste.js" /* webpackChunkName: "component---src-pages-taste-js" */),
  "component---src-pages-tasting-room-mdx": () => import("./../../../src/pages/tasting-room.mdx" /* webpackChunkName: "component---src-pages-tasting-room-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-pages-wenatchee-mdx": () => import("./../../../src/pages/wenatchee.mdx" /* webpackChunkName: "component---src-pages-wenatchee-mdx" */),
  "component---src-pages-wine-index-mdx": () => import("./../../../src/pages/wine/index.mdx" /* webpackChunkName: "component---src-pages-wine-index-mdx" */),
  "component---src-pages-wine-leavenworth-wine-tasting-mdx": () => import("./../../../src/pages/wine/leavenworth-wine-tasting.mdx" /* webpackChunkName: "component---src-pages-wine-leavenworth-wine-tasting-mdx" */)
}

